.mat-menu-panel,
.mat-mdc-menu-panel {
  width: 100%;
  background: #fff;
  padding: 4px !important;

  .mat-mdc-menu-content,
  .mat-menu-content:not(:empty) {
    padding-top: 0;
    padding-bottom: 0;
    padding: 0 !important;

    .mat-mdc-menu-item,
    .mat-menu-item {
      height: auto;
      border-bottom: 1px solid #f0f0f0;
      padding: 4px 20px;

      &:last-of-type {
        border-bottom: 0;
      }

      &:focus {
        box-shadow: inset 0 0 0 0.2rem rgb(48 128 145 / 80%) !important;
      }
    }
  }
}

.mat-option {
  font-family: 'hknova';
  border-bottom: 1px solid #f0f0f0 !important;
}

.mat-menu-panel {
  min-height: auto !important;
}

.mat-menu-item {
  font-family: hknova;
  font-size: inherit;
  padding: ($spacer / 4) $spacer !important;
}
