@import 'variables';

.table-container {
  overflow: auto;
}

table.mat-table,
table.mat-mdc-table {
  font-family: $heading_font_stack;

  width: 100%;

  tr {
    border-bottom: 1px solid #e5e5e5;
  }

  td,
  th {
    padding: $spacer $spacer;
    vertical-align: middle;
    white-space: nowrap;
    text-align: left;
    min-height: 62px;
    height: 62px;

    &:first-child,
    &:first-child {
      padding-left: 0;
    }

    .btn-sm {
      margin-top: -7px;
      margin-bottom: -3px;
    }
  }

  th {
    background: none;
    color: #fff;
    font-weight: bold;
    white-space: nowrap;
    border-bottom: 3px solid $primary;
    border-top: 1px solid $primary;
    color: $primary;
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: .3px;

  }
}

.sticky-table-container {
  position: relative;

  .sticky-actions {
    position: absolute;
    top: 64px;
    right: 0;
    box-shadow: -8px 0 8px -8px rgba(27,27,27,0.3);
    background: #fff;
    display: flex;
    flex-direction: column;

    .btn-group {
      display: table-cell;
      // border-bottom: 1px solid #f0f0f0;
      padding: $spacer 0 $spacer $spacer;
      margin: 0;
      position: relative;
      vertical-align: middle;
      white-space: nowrap;
      text-align: right;
      height: 62px;

      &:after {
        content: "";
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;
        height: 1px;
        width: 100%;
        background: #f0f0f0;
      }
    }
  }

  th:last-of-type,
  td:last-of-type {
    padding-right: 120px !important;
  }
}

.mat-cell {
  font-size: inherit;
}
