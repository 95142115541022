html {
	width: 100%;
	overflow-x: hidden;
  scroll-behavior: smooth;
}

#search-results:target:before,
#main-content:target:before {
  content: "";
  display: block;
  height: 160px;
  margin: -160px 0 0;
}

body {
  overflow-x: hidden;
}

html.fixed,
body.fixed {
	overflow: hidden;
	position: fixed;
	width: 100%;
	height: 100%;
}

main {
  img {
    max-width: 100%;
  }
}

.scrolled #can-adverts {
  margin-bottom: 58px;
}

body {
  #can-adverts {
    height: 90px!important;
    overflow: hidden;
  }

	@media only screen and (max-width: 960px) {
    padding-top: 58px;

		#can-adverts {
			height: 50px!important;
		}
	}
}

.full-screen-map {
  #can-adverts {
    display: none!important;
  }
}

body.cke_editable {
	padding: 12px 20px!important;
}

body {
	background: #fff;
}

body.gray {
	background: #f8f8f8;
}

#app-container {
	position: relative;
	margin-top: 58px;
}

#can-adverts + #app-container {
	margin-top: 168px;
}

.map-view #app-container {
	padding-top: 0;
	margin-top: 0;
}

#content {
	overflow-x: hidden;
}

a {
	color: $primary;
	text-decoration: none;
	transition: background cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.3s, color cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.3s;
	cursor: pointer;
}

.mjs-content-left a:not(.button) {
  text-decoration: underline;
}

@media only screen and (max-width: 960px) {
	#can-adverts + #app-container {
    margin-top: 122px;
	}
}

html body .zEWidget-launcher {
	display: none!important;
}

html body.contact .zEWidget-launcher {
	display: block!important;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	html body.contact .zEWidget-launcher {
		width: 112px!important;
		height: 47px!important;
	}
}

.banner-ad {
 	background: #f9f9f9;
 	text-align: center;
}

#leaderboard {
	max-width: 728px;
	padding: 10px 0;
	display: inline-block;
}

select {
  background-image: url(/assets/img/icons/SVG/keyboard_arrow_down.svg) !important;
  background-position: 99% !important;
  background-size: 18px;
  background-repeat: no-repeat;
  padding-right: 18px!important;
  min-width: 76px;
}

.entity-form {
  .form-group {
    border: 1px solid #d0d0d0;
    border-radius: 3px;
    position: relative;

    &.recaptcha-field {
      border: none;
      border-radius: 0;
    }

    .form-control {
      &.recaptcha-field {
        border: none;
        border-radius: 0;
      }
    }

    > label:first-child {
      display: block;
      padding: 8px 10px;
      border-bottom: 1px solid #d0d0d0;
      font-weight: 600;

      .required-flag {
        float: right;
        font-size: 70%;
        color: $danger;
      }
    }

    > input:not(:first-child),
    > select:not(:first-child) {
      width: calc(100% - 20px);
      margin: 10px;
    }

    .invalid-feedback {
      background: $danger;
      color: #fff;
      width: calc(100% - 20px);
      border-radius: 3px;
      padding: 0 10px;
      line-height: 22px;
      margin: 10px;
      font-weight: 600;

      > * {
        margin-bottom: 0;
      }
    }

    .description {
      padding: 6px 10px;
      border-top: 1px solid #d0d0d0;
      font-size: 90%;

      > p:last-of-type {
        margin-bottom: 0;
      }

      button {
        height: 44px;
        align-self: center;
      }
    }
  }
}

[name="__cmpLocator"] {
  height: 0;
}


select {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  -ms-appearance: none !important;
}

.btn,
button,
input[type="submit"] {
  font-weight: 500 !important;
}
