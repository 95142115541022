html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, form,
main {
	display: block;
}

article:not(.no-clear):after,
aside:not(.no-clear):after,
details:not(.no-clear):after,
figcaption:not(.no-clear):after,
figure:not(.no-clear):after,
footer:not(.no-clear):after,
header:not(.no-clear):after,
hgroup:not(.no-clear):after,
menu:not(.no-clear):after,
nav:not(.no-clear):after,
section:not(.no-clear):after,
div:not(.no-clear):after,
form:not(.no-clear):after,
ul:not(.no-clear):after,
ol:not(.no-clear):after,
main:not(.no-clear):after {
	content: " ";
	display: table;
	clear: both;
}

body {
	line-height: 1;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
	display: table;
}

html {
	box-sizing: border-box;
	-webkit-text-size-adjust: none;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

.noshow,
.element-invisible {
	position: absolute;
	left: -10000000px;
}

//u {
//	text-decoration: none!important;
//}

.list-style-none {
	list-style-type: none!important;

	li {
		list-style-type: none!important;
	}
}

.no-after {
	&:after {
		display: none!important;
	}
}

select::-ms-expand {
	display: none;
}

.toast-container {
  display: flex;
	flex-direction: column;
}
