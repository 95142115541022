@import 'src/styles/variables';

.tab-navigation {
  margin: -30px 0 30px;
  position: relative;

  @media only screen and (max-width: 960px) {
    margin: -30px 0 20px;
  }

  ul {
    list-style-type: none;
    margin: 0;

    @media only screen and (max-width: 400px) {
      width: calc(100% + 15px);
      margin-left: -7px;
    }

    li {
      display: block;
      float: left;

      a {
        display: block;
        padding: 0 16px;
        line-height: 36px;
        border: 2px solid $primary;
        border-top: 0;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        font-weight: 600;
        margin-right: 4px;
        font-size: 80%;
        box-shadow: inset 0 4px 6px -4px rgba(0, 0, 0, 0.4);

        @media only screen and (max-width: 400px) {
          padding: 0 6px;
          border-width: 1px;
        }

        &.active {
          background: $primary;
          color: #fff;
          box-shadow: none;
        }
      }
    }
  }
}
