.ql-toolbar.ql-snow {
  span {
    &.ql-picker-label {
      > svg {
        vertical-align: top;
      }
    }
  }
}

quill-editor {
  width: 100%;
  vertical-align: top;
}
