thead {
  background: none;
}

.mjs-left-content {
	table {
    width: 100%;
    margin: 0 0 40px 0;
    border: 1px solid #e5e5e5;

    th {
      padding: 18px 12px;
      font-weight: 600;
      color: #fff;
      background: #555;

      > :last-child {
        margin-bottom: 0;
      }
    }

    tr {
      border-bottom: 1px solid #e5e5e5;

      &:last-child {
        border: none;
      }
    }

    td {
      padding: 12px;
      border-right: 1px solid #e5e5e5;

      &:last-child {
        border-right: none;
      }

      > :last-child {
        margin-bottom: 0;
      }
    }

    ul,
    ol {
      margin-left: 26px;
    }
  }
}



.mjs-list {
	background: #fff;
	position: relative;
	margin: 0 0 40px 0;
	width: 100%;

  tr {
  	border-bottom: 1px solid #e5e5e5;
  }

  tbody tr {
  	border-left: 1px solid #e5e5e5;

    &:nth-child(even) {
      background: #f9f9f9
    }
  }

  th {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    background: $primary;
    font-family: $heading-font-stack;
    color: #fff;
    padding: 12px;
    border-right: 1px solid rgba(27,27,27,0.05);
    text-align: left;
    vertical-align: middle;
    white-space: nowrap;
  }

  td {
    padding: 10px 12px;
    border-right: 1px solid #e5e5e5;
    text-align: left;
    vertical-align: middle;
    line-height: 22px;
  }

  .center {
  	text-align: center;
  }

  .narrow {
  	width: 60px;
  }

  a {
  	display: inline-block;
  }

  svg {
  	width: 24px;
  	height: 24px;
  	display: inline-block;
  	vertical-align: middle;
  }

  .long-field {
    min-width: 300px;
  }

  .narrow.center {
    font-size: 0;
  }

  th.narrow.center {
    font-size: 12px;
  }

  svg use {
    fill: #555;
  }

  svg.icon-close-alert-outline {
    width: 22px;
    height: 22px;
    border-radius: 50%;
  }

  svg.icon-close-alert-outline use {
    fill: #B33A3A;
  }

  svg.icon-document-edit use {
    fill: $primary;
  }

  .view-docs {
    background: none;
    padding: 0;
    border-radius: 0;
    margin: 0;
  }
}


.mjs-table-scroll {
	overflow-x: scroll;
	margin: 0 0 40px 0;

  > table {
  	margin: 0;
  }
}

.mjs-table-scroll::-webkit-scrollbar {
	width: 100%;
}

.mjs-table-scroll::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
}

.mjs-table-scroll::-webkit-scrollbar-thumb {
	background: $primary;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}
.mjs-table-scroll::-webkit-scrollbar-thumb:window-inactive {
	background: rgba(53,140,160,0.4);
  border-radius: 6px;
}

.view-docs svg {
  width: 18px;
  height: 18px;
}

.view-docs svg use {
  fill: $primary
}

.job-docs {
  position: fixed;
  top: 100%;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  background: rgba(27,27,27,0.85);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940);

  &.open {
    opacity: 1;
    top: 0;
  }

  h4 {
  	line-height: 24px;
  	margin: 0 0 12px 0;
  }

  .inner {
    width: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    background: #fff;
    padding: 10px;
    transform: translateY(-50%);
    margin: 0 0 0 -150px;
    box-shadow: 0 0 10px #000;

    a {
    	display: block;
    	word-wrap: break-word;
    	background: #f7f7f7;
    	border: 1px solid #e5e5e5;
    	padding: 8px 40px 8px 10px;
    	margin: 0 0 4px 0;
    	line-height: 20px;
    	position: relative;
    	color: #444;

      svg {
      	width: 20px;
      	height: 20px;
      	float: left;
      	position: absolute;
      	right: 10px;
      	top: 50%;
      	margin-top: -10px;

        use {
        	fill: $primary;
        }
      }
    }

    a:last-of-type {
    	margin: 0;
    }
  }

  button {
  	font-size: 0;
  	position: absolute;
  	right: -12px;
  	top: -12px;
  	padding: 0;
  	width: 26px;
  	height: 26px;
  	background: none;
  	text-align: center;
  	background: #fff;
  	border-radius: 50%;
  	box-shadow: 0 0 3px rgba(27,27,27,0.5);

    svg {
    	width: 24px;
    	height: 24px;

      use {
      	fill: $primary;
      }
    }
  }
}

.action-inner svg.inline-svg,
svg.inline-svg {
  width: 20px;
  height: 20px;
  bottom: -3px;
  position: relative;
}

tr.withdrawn {
	opacity: 0.5;
}

@media only screen and (max-width: 960px) {

	.mjs-table-scroll {
		box-shadow: inset 0 0 5px rgba(27,27,27,0.5);
	}

}


.table-container {
  scrollbar-face-color: $primary;
  scrollbar-track-color: #f9f9f9;

  &::-webkit-scrollbar {
    width: 8px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f7f7f7;
    width: 8px;
    box-shadow: inset 0 4px 4px rgba(27, 27, 27, 0.1);
    height: 10px;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: $primary;
    border: 4px solid transparent;
    border-radius: 6px;
  }
}

.btn-group {
  display: inline-block;
  position: relative;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 10px !important;


  > button,
  > a {
    cursor: pointer;
    white-space: nowrap;
    color: $primary;
    padding-right: 1.4rem;
    position: relative;
    top: 50%;
    transform: translateY(-50%);

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 7px;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid $primary;
      display: block;
    }

    &:hover {
      background-color: $primary;
      color: #fff;

      &:after {
        border-top: 4px solid #fff;
      }
    }
  }

  > div {
    position: absolute!important;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    list-style-type: none;
    width: auto;
    z-index: 1;
    right: 0;
    white-space: nowrap;

    button,
    a {
      display: block;
      padding: 6px 10px;
      text-decoration: none!important;
      font-size: 14px;
      text-align: center;
      font-weight: 500;
    }

    &.divider {
      padding: 0;
      border-bottom: 1px solid #cccccc;
    }
  }
}

// .mat-column-categories span {
//   font-size: 10px;
//   line-height: 10px;
//   border: 1px solid $primary;
//   border-radius: 3px;
//   padding: 4px 6px;
//   font-weight: 500;
//   margin-right: 2px;
// }

td {
  &.mat-table-sticky {
    background: #fff;
    text-align: center!important;
    box-shadow: -3px 0 7px -2px rgba(27,27,27,0.2);
    border-left: 1px solid #e5e5e5;
  }
}
