.job-description,
.wysiwyg-content {
  ul {
    position: relative;
    margin: 0 0 24px 24px;

    li {
      position: relative;
      list-style-type: disc;

      ul {
        li {
          list-style-type: square;

          ul {
            li {
              list-style-type: circle;
            }
          }
        }
      }
    }
  }

  ol {
    position: relative;
    margin: 0 0 24px 24px;

    li {
        position:relative;
        list-style-type:decimal;

      ol {
        li {
          list-style-type: lower-latin;

          ol {
            li {
              list-style-type:lower-roman;
            }
          }
        }
      }
    }
  }

  img {
    max-width: 100% !important;
  }

  table {
    width: 100%;
    margin: 0 0 40px 0;
    border: 1px solid #e5e5e5;

    th {
      padding: 18px 12px;
      font-weight: 600;
      color: #fff;
      background: #555;

      > :last-child {
        margin-bottom: 0;
      }
    }

    tr {
      border-bottom: 1px solid #e5e5e5;

      &:last-child {
        border: none;
      }
    }

    td {
      padding: 12px;
      border-right: 1px solid #e5e5e5;

      &:last-child {
        border-right: none;
      }

      > :last-child {
        margin-bottom: 0;
      }
    }

    ul,
    ol {
      margin-left: 26px;
    }
  }

  p {
    &:empty,
    &:blank {
      display: none;
    }
  }

  h2 {
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 20px;
    clear: both;

    &:before {
      content: "";
      display: block;
      width: 50px;
      height: 3px;
      position: absolute;
      left: 0;
      bottom: 0;
      background: $primary;
    }

    &.text-center {
      &:before {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}


#freeform_form {
  ol {
    margin: 0;

    li {
      list-style-type: none;
      margin: 0 0 10px 0;
      display: block;
      width: 100%;
      clear: both;
    }
  }
}

.list-unstyled,
.list-unstyled li {
  list-style-type: none !important;
  margin-left: 0 !important;
}
